// 电子章查验结果
<template>
  <base-dialog
    :visible.sync="currentVisible"
    :showFooter="false"
    title="电子章查验结果"
    class="pad20"
  >
    <div>
      <div class="tableHeader">您的文件有以下{{signCheckData.totalCount || '0'}}个签章，详情信息如下</div>
      <base-table
       :columns="columns"
          :showPage="false"
          :tableAttrs="{
            data:infoData,
            stripe: true,
            'max-height':'500'
          }"
          :dataSource.sync="infoData"
          :is-caculate-height="false"
      >
       <template slot="code" slot-scope="scope">
        {{scope.$index+1}}
      </template>
      <template slot="validFlag" slot-scope="scope">
        <i v-if="scope.row.validFlag===1" class="iconfont iconchenggong1"></i>
        <i v-if="scope.row.validFlag===2" class="iconfont iconshibai1"></i>
      </template>
      <template slot="certValid" slot-scope="scope">
        <p>{{ newFormatDate(scope.row.certValidStart, 'YY/MM/DD hh:mm:ss')+"至"}}</p>
        <p>{{ newFormatDate(scope.row.certValidEnd, 'YY/MM/DD hh:mm:ss')}}</p>
      </template>
      </base-table>
    </div>
    <template slot="footer">
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible=false"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { signCheckConfig } from '../utils/seal-datails-config'
import { signApi } from '@/api/businessApi'
import { formatDate } from '@/utils/auth/common'
export default {
  components: { baseDialog, BaseButton, BaseTable },
  name: 'detailDialog',
  props: {
    signCheckData: Object,
    signCheckvisible: Boolean
  },
  data () {
    return {
      count: 0,
      infoData: []
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.signCheckvisible
      },
      set (val) {
        this.$emit('update:signCheckvisible', val)
      }
    },
    columns () {
      return signCheckConfig(this)
    },
    newFormatDate () {
      return formatDate
    }
  },
  watch: {
    currentVisible (val) {
      if (val && this.signCheckData.pdfCheckLogId) {
        this.getList()
      }
    }
  },
  mounted () {
  },
  methods: {
    getList () {
      signApi.electronChapterList({ pdfCheckLogId: this.signCheckData.pdfCheckLogId }).then(res => {
        if (res.data) {
          this.infoData = res.data
        }
      })
    },
    // 修改值改变弹窗内容
    setCount () {
      this.count++
    }
  }
}
</script>
<style lang="scss" scoped>
.tableHeader{
  margin-bottom: 10px;
}
.iconshibai1{
  color: red;
}
.iconchenggong1{
  color: #52C4A8;
}
</style>
