//签约文件详情
<template>
  <div class="signFileDetails">
    <!-- 返回 -->
    <backtitle lable="签约管理" />
    <div class="signBackground">
      <!-- 头部汇总 -->
      <sign-header :summaryData="summaryData"></sign-header>
      <!-- 建行提示 -->
      <!-- <div class="warging" v-if="role === '20'"><i class="el-icon-warning"/>请确认供应商以下文件是否可由稳诚一方协助盖章：</div> -->
      <!-- 头部信息 -->
      <div class="headeritle">
        <sign-title :label="role === '20'?'合同文件':'盖章文件'" :amount="amount">
          <template v-slot:btn>
            <base-button label="批量下载" @click="downAll" icon="iconfont iconxiazai"></base-button>
            <base-button
              v-if="sealType === '1'"
              label="文件盖章序"
              @click="showSealOrder"
              icon="iconfont iconwenjiangaizhangxu"
            ></base-button>
          </template>
        </sign-title>
      </div>
      <!-- 表格 -->
      <base-table
        class="main-page-table"
        :columns="columns"
        :showPage="false"
        :tableAttrs="{
          stripe: false,
          data: tableData,
          border: true,
          spanMethod: objectSpanMethod,
          rowClassName:tableRowClassName
        }"
        :dataSource.sync="tableData"
        :api="api"
        :loadCount="loadCount"
        :webPage="false"
      >
        >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <template slot="msgLinkAddress" slot-scope="scope">
         <div :data-clipboard-text='scope.row.msgLinkAddress' style="cursor: pointer" @click="msgLinkAddressCopy('msgLinkAddress'+scope.$index,scope.row.msgLinkAddress)" :class="'msgLinkAddress'+scope.$index"> {{ scope.row.msgLinkAddress  }}</div>
        </template>
          <template slot="action" slot-scope="scope">
          <icon-button
            @click="showPreview(scope.row)"
            content="预览"
            icon="iconfont iconyulan"
          />
          <icon-button
            @click="signCheck(scope.row)"
            content="电子章查验"
            icon="iconfont iconliushui"
          />
        </template>
      </base-table>
    </div>
    <!-- 底部按钮 -->
    <!-- <div class="footer" v-if="role==='22'&&status==='0'">
      <base-button
        :label="sealType==='0'?'鲜章盖章下载':'自动发送签章'"
        @click="confirmSeal"
      />
    </div> -->
    <div class="footer" v-if="role==='22'&&sealType==='0'">
      <base-button
        label="鲜章盖章下载"
        @click="confirmSeal"
      />
    </div>
    <preview-dialog
      :previsible.sync="previsible"
      :fileId="fileId"
      :dialogType="'list'"
      :fileType="fileType"
    ></preview-dialog>
    <preView :fileId="fileId" :fileType="fileType" :isOpen="true" :count="fileCount"/>
    <!-- 文件信息弹窗 -->
    <sign-file-details
      :fileInfovisible.sync="fileInfovisible"
    ></sign-file-details>
    <!-- 文件盖章序 -->
    <seal-order-dialog
      :count="count"
      :serlOrderVisible.sync="serlOrderVisible"
      :orderData="orderTableData[0]"
      :disorderData="orderTableData[1]"
    ></seal-order-dialog>
    <!-- 电子章查验结果弹窗 -->
    <sign-check :signCheckvisible.sync='signCheckvisible' :signCheckData='signCheckData'></sign-check>
  </div>
</template>
<script>
import preView from '@/components/pre-view/pre-view.vue'
import signCheck from '../components/sign-check.vue'
import backtitle from '@/pages/business/components/backtitle.vue'
import SignHeader from '../../components/sign-header.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { dataTable } from '../utils/details-config'
import PreviewDialog from '../../components/preview-dialog.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import signFileDetails from '../components/file-info-dailog.vue'
import SignTitle from '../../components/sign-title.vue'
import SealOrderDialog from '../components/seal-order-dailog.vue'
import Storage from '@/utils/storage'
import { signApi } from '@/api/businessApi'
import { downFile } from '@/utils/devUtils'
import { downAPi } from '@/api/fileApi'
import { seqFilterData, filterData, FilseTypeFilterData } from '../utils/utils'
import { getDictLists } from '@/filters/fromDict'

export default {
  components: {
    backtitle,
    SignHeader,
    BaseTable,
    PreviewDialog,
    IconButton,
    BaseButton,
    signFileDetails,
    SignTitle,
    SealOrderDialog,
    preView,
    signCheck
  },
  data () {
    return {
      fileCount: 0,
      orderTableData: [[], []], // 盖章序文件
      summaryData: {},
      count: 0,
      tableData: [],
      loadCount: 0,
      titleLabel: '',
      previsible: false,
      fileId: '',
      fileType: '',
      sealType: '', // 盖章类型 0鲜章  1 电子章
      fileInfovisible: false, // 文件信息弹窗
      signCheckvisible: false, // 电子章查验结果弹窗
      signCheckData: {}, // 电子章查验数据
      amount: '', // 合同文件数
      serlOrderVisible: false, // 文件盖章序
      role: '20', // 角色 默认是平台
      status: '' // 签约状态  0待签约
    }
  },
  created () {
    // 获取角色信息
    this.role = this.$store.state.user.userInfo.userType
  },
  mounted () {
    // 本地拿详情数据
    this.summaryData = Storage.getSession('signData')
    // 合同数量
    this.amount = this.summaryData.contractCount.toString()
    // 盖章类型
    // this.sealType = this.summaryData.suppliersSealType
    // 没有鲜章了，直接写死电子章
    this.sealType = '1'
    this.status = this.summaryData.status
    if (this.sealType === '0') {
      // 鲜章
      this.getSignDocList()
    } else {
      // 电子章
      this.getSignDetail()
    }
  },
  computed: {
    columns () {
      return dataTable(this)
    },
    api () {
      return signApi
    },
    // 盖章状态
    signStatus () {
      return getDictLists('SIGN_STATUS')
    },
    // 人员类别
    personType () {
      return getDictLists('PERSONNEL_TYPE')
    },
    // 通知状态
    noticeStatus () {
      return getDictLists('NOTICE_STATUS')
    },
    // 盖章类型
    signerType () {
      return getDictLists('SEAL_TYPE')
    }
  },
  methods: {
    // 高亮
    tableRowClassName ({ row, rowIndex }) {
      if (row.fileValidType === 2 || row.fileValidType === 3) {
        return 'highlight'
      } else {
        return ''
      }
    },
    // 电子章查验
    signCheck (data) {
      this.signCheckvisible = true
      this.signCheckData = data
    },
    // 复制短链接
    msgLinkAddressCopy (data, url) {
      var clipboard = new this.Clipboard('.' + data)
      clipboard.on('success', e => {
        // 释放内存
        this.success('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.warning('该浏览器不支持自动复制')

        // 释放内存
        clipboard.destroy()
      })
    },
    // 表格合并规则
    objectSpanMethod ({ row, column }) {
      if (this.sealType === '0') {
        // 鲜章无合并
        return this.tableData
      }
      if (column.property === 'docName') {
        const _row = row.docIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'fileName') {
        const _row = row.currentFileIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'fileCount') {
        const _row = row.currentFileIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'action') {
        const _row = row.currentFileIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 签约操作
    confirmSeal () {
      if (this.sealType === '0') {
        alert('鲜章')
      } else if (this.sealType === '1') {
        alert('电子章')
      }
    },
    // 获取电子章盖章文件
    getSignDetail () {
      this.api.getSignDetail({ taskId: this.summaryData.keyId }).then((res) => {
        this.tableData = FilseTypeFilterData(res.data, 'docName', 'docIdRowSpan')
        // this.tableData = filterData(res.data, 'fileName', 'currentFileIdRowSpan')
      })
    },
    // 获取鲜章盖章文件
    getSignDocList () {
      this.api.getSignDocList({ taskId: this.summaryData.keyId }).then((res) => {
        this.tableData = res.data
        console.log(this.tableData, ' this.tableData')
      })
    },
    // 获取文件盖章序
    getTaskDocSeq () {
      this.api.getTaskDocSeq({ taskId: this.summaryData.keyId }).then((res) => {
        this.orderTableData[0] = seqFilterData(res.data.seqDocs, 'seq', 'seqRowSpan')
        this.orderTableData[1] = filterData(res.data.noSeqDocs, 'docName', 'docNameRowSpan')
        this.count++
      })
    },
    // 批量下载
    downAll () {
      const filtKeyIds = this.tableData.map((item) => {
        return item.currentFileId
      })
      const newFiltKeyIds = [...(new Set(filtKeyIds))]

      if (filtKeyIds.length > 0 && newFiltKeyIds[0]) {
        downAPi
          .batchDownload({ keyIds: newFiltKeyIds, fileName: '合同文件' })
          .then((res) => {
            downFile(res)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 补发电子章盖章任务    暂时不上
    // supplement () {
    //   alert('补发电子章盖章任务')
    // },
    // 文件信息弹窗 暂时不上
    // showFileInfo () {
    //   this.fileInfovisible = true
    // },
    // 文件盖章序弹窗
    showSealOrder () {
      this.getTaskDocSeq()
      this.serlOrderVisible = true
    },
    // 展示预览弹窗
    showPreview (data) {
      this.fileId = data.currentFileId ? data.currentFileId : ''
      const fileSuffix = data.fileName ? data.fileName.split('.') : []
      this.fileType = fileSuffix[fileSuffix.length - 1]
      this.fileCount++
    },
    // 签约状态显示    2021/12/15 需求变更,状态写死   2022/7/5 需求变更
    changeSignStatus (row) {
      if (row.signStatus === '0') {
        return '未签署'
      } else if (row.signStatus === '1' || row.signStatus === '2') {
        return '签署中'
      } else if (row.signStatus === '3') {
        return '已签署'
      }
    },
    // 签约状态显示
    changeSignStep (row) {
      if (row.signStep === '0' || row.signStep === '1' || row.signStep === '2') {
        return '待认证'
      } else if (row.signStep === '3' || row.signStep === '4' || row.signStep === '5') {
        return '认证完成'
      }
    }

  }
}
</script>
<style lang="scss" src='../index.scss' scoped />
